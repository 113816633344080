

.slick-box {
    width: 80%;
    left: 10%;
}
  
  .slick-box-slide {
    display: flex;
    justify-content: center;
  }
  
  .box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    border-radius: 12%;
    background-color: #121329; 
  }
  
  .logo-container {
    margin-bottom: 10px;
  }
  
  .logo {
    width: 150px;
    height: auto;
  }
  
  .logo-name {
    font-size: 25px;
    margin-bottom: 5px;
    justify-content: center;
    color: #ffffff;
    font-family: "Consolas", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .proficiency-text {
    font-size: 14px;
    color: #ffffff;
    font-family: "Consolas", Tahoma, Geneva, Verdana, sans-serif;
  }