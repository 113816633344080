.container {
    display: flex;
    justify-content: center;
}

.project-section {
    display: flex;
    background-color: #121329;
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
    width: 75%;
    max-width: 1000px;
}

.project-image {
    flex: 1;
    width: 512px;
    margin-right: 20px;
}

.project-image img {
    width: 100%;
    border-radius: 10px;
}

.project-description {
    flex: 2;
}

.project-description h2 {
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    font-family: "Consolas", Tahoma, Geneva, Verdana, sans-serif;
  }
  
.project-description p {
    color: white;
    font-size: 16px;
    margin-bottom: 15px;
    font-family: "Consolas", Tahoma, Geneva, Verdana, sans-serif;
}
  
.project-links a {
    margin-right: 10px;
    text-decoration: none;
    color: #3498db;
    font-size: 20px;
}

@media screen and (max-width: 450px) {
    .project-image {
      display: none;
    }
}